// Override SASS variables Bootstrap

// COLORS
$primary: #FF385D;
$secondary: #2B8639;
$tertiary: #FF4D29;
$success: #004A23;
$info: #9EFAAC;
$warning: #F7F06D;
$danger: #dc3545;
$light: #FFFFFF;
$dark: #002612;
$disabled: #9B9B9B;
$gray: #F6F6F6;
$disabled-text: #809288;
$gray-800: #454545;
$green-500: #00853F;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "tertiary":   $tertiary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "disabled":   $disabled,
);

// INPUTS
$input-border-radius: 10px;

// BUTTONS
$btn-border-radius: 10px;
$btn-line-height: 1.645rem;
$btn-font-size: 1rem;
$btn-padding-y: 0.6775rem;
$btn-font-weight: 700;
$btn-border-width: 2px;
$btn-link-color: $dark;
$btn-link-hover-color: $dark;

//BADGE
$badge-font-size: 0.75rem;
$badge-border-radius: 18px;
$badge-padding-y: 2px;

//CHECKS
$form-check-input-focus-box-shadow: none;
$form-check-input-checked-bg-color: #8CD400;
$form-check-input-checked-border-color: #8CD400;
$form-check-input-width: 1.25em;
$form-check-padding-start: 1.875rem;
$form-check-input-border-radius: 5px;
$form-check-input-focus-border: 2px solid $success;
$form-check-input-border: 2px solid $success;

//CARDS
$card-border-radius: 0.3125rem;

// FONT-FAMILY
$axiforma-light: "AxiformaLight", "sans-serif";
$axiforma-italic: "AxiformaItalic", "sans-serif";
$font-family-base: "Axiforma", "sans-serif";
$axiforma-medium: "AxiformaMedium", "sans-serif";
$axiforma-semibold: "AxiformaSemiBold", "sans-serif";
$axiforma-bold: "AxiformaBold", "sans-serif";

$font-family-secondary: "Lato", "sans-serif";

body {
    background-color: $gray !important;
}

.font-family-axiforma {
    font-family: $font-family-base;
}
.font-family-lato {
    font-family: $font-family-secondary;
}

.no-wrap {
    white-space: nowrap;
}