/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@font-face {
    font-family: AxiformaBold;
    src: url(assets/fonts/Axiforma-Bold.ttf);
}

@font-face {
    font-family: AxiformaItalic;
    src: url(assets/fonts/Axiforma-Italic.ttf);
}

@font-face {
    font-family: AxiformaLight;
    src: url(assets/fonts/Axiforma-Light.ttf);
}

@font-face {
    font-family: AxiformaMedium;
    src: url(assets/fonts/Axiforma-Medium.ttf);
}

@font-face {
    font-family: AxiformaSemiBold;
    src: url(assets/fonts/Axiforma-SemiBold.ttf);
}

@font-face {
    font-family: Axiforma;
    src: url(assets/fonts/Axiforma-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: Axiforma;
    src: url(assets/fonts/Axiforma-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: Axiforma;
    src: url(assets/fonts/Axiforma-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: Axiforma;
    src: url(assets/fonts/Axiforma-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: Axiforma;
    src: url(assets/fonts/Axiforma-Bold.ttf);
    font-weight: 700;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'variables';
@import 'bootstrap/scss/bootstrap';
@import 'swiper/css/bundle';
@import 'swiper-custom';

a {
    color: $primary;
    text-decoration: underline;
}

.shared-container {
    @media (max-width: 768px) {
        padding-inline: 1.25rem;
    }
}

.shared-container-d {
    @media (max-width: 768px) {
        padding-inline: 0.875rem;
    }
}

.card-size {
    max-width: 31.25rem;

    @media only screen and (max-width: 575px) {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }

    @media only screen and (min-width: 768px) {
        max-width: 25.25rem;
    }
    .text-h1 {
        font-weight: 700;
        font-size: 27px;
        letter-spacing: -0.02em;
        margin-bottom: 0;
        line-height: 29px;
        @media (max-width: 767px) {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.75rem;
            letter-spacing: 0em;
            margin-bottom: .25rem;
        }
        @media (max-width: 990px) {
            font-size: 20.25px;
        }
        @media (max-width: 768px) {
            font-size: 1.5rem;
        }
    }
}
.container-examples-components {
    width: 100%;
    height: 600px;
    background-color: #a8a8a8;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
}

.pagination-viva {
    .pagination {
        display: flex;
        align-items: center;
        .page-item.active .page-link {
            color: #00853f !important;
        }
        .page-item .page-link {
            background: transparent !important;
            color: #002612 !important;
            font-weight: bold !important;
            font-size: 16px !important;
            line-height: 22px !important;
            border: none !important;
        }
        .page-item:first-child {
            .page-link {
                border-radius: 50%;
                color: #ffffff !important;
                background: #002612 !important;
            }
        }
        .page-item:last-child {
            .page-link {
                border-radius: 50% !important;
                color: #ffffff !important;
                background: #002612 !important;
            }
        }
        .page-item.disabled:first-child {
            .page-link {
                border-radius: 50% !important;
                background: #e5e5e5 !important;
            }
        }
        .page-item.disabled:last-child {
            .page-link {
                border-radius: 50% !important;
                background: #e5e5e5 !important;
            }
        }
        .page-item {
            .page-link:focus {
                box-shadow: none;
            }
        }
    }
}

.grecaptcha-badge {
    display: none !important;
}

.mb-40 {
    margin-bottom: 2.5rem;
}

mat-datepicker-content {
    border-radius: 10px !important;
    box-shadow: 0px 3px 6px 2px #00000026;
    .mat-calendar-content {
        padding: 0px 18px 18px 18px;
    }
    .ng-star-inserted {
        font-family: 'Lato', 'sans-serif';
        font-style: normal;
        font-size: 13px !important;
        font-weight: 700 !important;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center !important;
        color: #004a23 !important;
    }
    .mat-calendar-table-header-divider::after {
        background: #e5e5e5 !important;
        width: 100%;
        left: 0 !important;
        right: 0 !important;
    }
    .mat-calendar-body-label {
        height: 0 !important;
        padding: 0 !important;
        opacity: 0;
    }
    .mat-calendar-table-header-divider {
        display: none;
    }
    .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
            .mat-calendar-body-comparison-identical
        ) {
        border-color: #004a23 !important;
    }
    .mat-calendar-body-selected {
        background-color: #8cd400;
        border-radius: 5px;
    }
    .mat-calendar-body-cell-content {
        font-family: 'Axiforma';
        font-weight: 500;
        padding-top: 4px;
        color: #004a23;
    }
    .mat-calendar-body-disabled
        > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
            .mat-calendar-body-comparison-identical
        ) {
        color: #e5e5e5;
    }
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover {
        .mat-calendar-body-cell-content {
            background-color: #8fd6004d !important;
        }
    }
}

.shadow-card {
    box-shadow: 0 1px 6px 0px rgba(0, 0, 0, 0.1490196078);
    border: 0;
}

.placeholder {
    background-color: #e4e0e0 !important;
    border-radius: 5px;
}

.btn-outline-primary:hover, .btn-outline-primary:active {
    color: white !important;
}
.btn-primary {
    color: white;
}
.btn-secondary {
    color: white;
}
.btn-tertiary {
    color: white;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    color: white !important;
}
.btn-secondary:hover {
    background-color: #38ac49;
    border-color: #38ac49;
}
.btn-tertiary:hover {
    background-color: #ff856d;
    border-color: #ff856d;
}
.btn-outline-success:hover {
    background-color: #2b8639;
    border-color: #2b8639;
}
.btn-outline-dark:hover {
    .btn-icon {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(15deg)
            brightness(102%) contrast(100%);
    }
}

.btn-outline-primary:hover {
    color: white;
}
.container-card-giftcard-design {
    @media only screen and (max-width: 990px) {
        margin-bottom: 20px;
    }
    @media only screen and (max-width: 767px) {
        margin-bottom: 2.5rem;
    }
}